.MuiDialog-container {
  /* height: 900px; */
  /* height: 900px !important; */
  /* ^^ Improved code coz giving height to anything in css is like poison! ~sahil, also fixed the unnecessary height issue in mobile screen as well. */
}

.MuiDialog-scrollPaper {
  display: block !important;
  position: absolute;
  /* left: 15%; */
  /* ^^COMMENTED coz we want to center elements in FullscreenPopup ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎ */
}
@media only screen and (max-width: 600px) {
  .MuiDialog-scrollPaper {
    display: block !important;
    position: absolute;
    /* left: -58px; */
    /* ^^COMMENTED coz we want to center elements in FullscreenPopup ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎ */
  }
}
.card {
  background-color: #010101 !important;
  border: none;
}
.MuiDialogActions-root {
  padding: 0 !important;
}
.card {
  background-color: #010101 !important;
  border: none;
}
@media only screen and (max-width: 500px) {
  .MuiDialog-paperFullScreen {
    overflow: auto;
  }
}

.loading-screen-listing-delisting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
