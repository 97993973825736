body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Atten New";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Atten New";
  src: local("Atten New"),
    url("./assets/AttenNewBold.woff") format("woff");
  font-weight: normal;
}