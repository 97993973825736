.Search {
  background-color: #151515;
  height: 48px;
  border-radius: 24px;
  padding: 10px 20px;
  width: 350px;
  min-width: 320px;
}

.Search input {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  margin-left: 10px;
  flex: 1;
}
