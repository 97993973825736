.home-list-delist-popup {
  background-color: rgba(0, 0, 0, 0.63);
  /* background-color: rgba(0, 0, 0, 0.85); */
  color: #bc936b;
  /* z-index: 3; */
  z-index: 1300;
  /* ^^ Setting that to 1099 will still cause Header-minimal to show above it, so 1300 is the minium working value. */
  top: 0;
  font-weight: 'bold';
  text-align: 'center';
}

.small-box {
  background: #000;
  /* border: 2px solid pink; */
  min-width: 298px;
  padding: 32px;
  box-shadow: 0px 10px 40px rgba(200, 161, 120, 0.2);
}

.small-box-message {
  color: white;
  padding-top: 20px;
}
