.TicketCard {
  width: auto;
  height: 250px;
  background-color: black;
  overflow: hidden;
}

.TicketCard :is(.upper-content, .bottom-content) {
  padding: 0 10px;
}

.TicketCard :is(.teams-div, .date-div, .seat-det-div) {
  padding: 0 17px;
}

.TicketCard .upper-content :is(.date-div, .teams-div) {
  z-index: 1;
}

.TicketCard .upper-content .teams-div label {
  text-align: center;
  margin: 0;
}
.TicketCard .upper-content .teams-div .state-label {
  color: #bc936b;
  font-size: 16px;
}

.TicketCard .upper-content .teams-div .team-label {
  color: #e7e1c5;
  font-size: 24px;
}

.TicketCard .upper-content .teams-div .vs-label {
  color: white;
  margin: 10px;
}

.TicketCard .upper-content img {
  top: 0;
  left: 0;
}
.TicketCard .upper-content .date-div {
  color: white;
  font-size: 12px;
  height: 27px;
  padding: 7px 15px;
  margin: 0 !important;
  background: rgba(196, 196, 196, 0.08);
}

.TicketCard .separator {
  height: 0px;
  border: 1px solid rgba(188, 147, 107, 0.3);
}

.TicketCard .seat-det-div {
  margin: 10px 0;
}

.TicketCard .bottom-content .image-text-div {
  color: white;
  font-size: 16px;
}

.TicketCard .bottom-content .image-text-div img {
  margin-right: 3px;
}
.TicketCard .bottom-content .action-button {
  height: 42px;
  width: 170px;
  margin-top: 10px;
  cursor: pointer;
}
.TicketCard .de-list {
  border: 2px solid #c57a1e;
  border-radius: 21px;
  font-size: 14px;
  color: #c57a1e;
}
.TicketCard :is(.de-list, .list):hover {
  box-shadow: 0px 4px 22px rgba(197, 122, 30, 0.4);
}
.TicketCard .list {
  background: #c57a1e;
  border-radius: 21px;
  font-size: 14px;
  color: white;
}
.TicketCard .sold-ticket {
  z-index: 2;
  background: rgba(0, 0, 0, 0.72);
  font-size: 40px;
  color: white;
  margin-bottom: 60px;
}

.TicketCard .bottom-separator {
  z-index: 2;
}

.TicketCard .sold-ticket {
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.TicketCard .sold-text {
  top: 25%;
}
